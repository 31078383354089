import React, { useState } from "react";
import {
  Form,
  Select,
  Button,
  Radio,
  Card,
  Divider,
  Input,
  message,
  Table,
} from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../config";
import { useEffect } from "react";
const { Option } = Select;

const Checkout = ({ setCartItemsCount }) => {
  const [cartItems, setCartItems] = useState([]);
  const [applyButtonEnable, setApplyButtonEnable] = useState(false);
  const [savedAddresses, setSavedAddresses] = useState([]);
  const navigate = useNavigate();
  const handleAddAddress = () => {
    navigate("/address");
  };
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("bitcoin");
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState(null);
  const [couponError, setCouponError] = useState("");
  const [refetch, setRefetch] = useState(false);

  const handleAddressChange = (value) => {
    setSelectedAddress(value);
  };

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const submitPay = async () => {
    try {
      const response = await axios.post(
        `${config.baseUrl}/api/checkout/`,
        {
          payment_method: paymentMethod,
          address_id: selectedAddress,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      if (response.data.success) {
        message.success("Payment submitted successfully");
        const checkout_hash = response.data.checkout_hash;
        setCartItemsCount(0);
        navigate(`/orderdetail/${checkout_hash}`);
      } else {
        message.error("Payment submission failed");
      }
    } catch (error) {
      message.error("Payment submission failed");
    }
  };

  const handleCheckout = () => {
    submitPay();
  };

  // 商品列表的列定义
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text) => `$${text.toFixed(0)}`,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Total",
      key: "subtotal",
      render: (text, record) =>
        `$${(record.price * record.quantity).toFixed(0)}`,
    },
  ];
  // 获取地址列表
  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await axios.get(
          `${config.baseUrl}/api/shipping_address`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        setSavedAddresses(
          response.data.map((address) => ({
            id: address.id,
            label: address.shipping_fullname,
            address: `${address.shipping_address}, ${address.shipping_city}, ${address.shipping_state}, ${address.shipping_zip} ,US,${address.shipping_phone}`,
          }))
        );
      } catch (error) {
        message.error("Get address list failed, please try again later");
      }
    };
    fetchAddresses();
  }, []);
  //get the checkout item
  useEffect(() => {
    const fetchCheckoutItem = async () => {
      try {
        const response = await axios.get(`${config.baseUrl}/api/checkout/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        const {
          coupon_used,
          coupon_code,
          discount_amount,
          discount_total,
          order_total,
        } = response.data;

        setCouponCode(coupon_code);
        setDiscount(discount_amount);
        setDiscountedTotal(discount_total);
        setTotalPrice(order_total);
        setApplyButtonEnable(!coupon_used);
      } catch (error) {
        if (error.response?.data?.error) {
          message.error(error.response.data.error);
          navigate("/");
        } else {
          message.error(
            "Failed to fetch checkout items, please try again later"
          );
        }
      }
    };
    fetchCheckoutItem();
  }, [refetch, navigate]);

  // 聚合数据的函数
  function groupByProductType(data) {
    const result = [];
    const groupedData = {};

    data.forEach((item) => {
      const productName = item.product.state;
      const productPrice = parseFloat(item.product.price); // 确保价格是数值类型

      // 如果产品类型还没有被添加到groupedData中，就创建一个新的对象
      if (!groupedData[productName]) {
        groupedData[productName] = {
          name: item.product.name,
          quantity: 0,
          price: productPrice, // 初始化总价
        };
      }

      // 更新产品的数量
      groupedData[productName].quantity += 1;
    });
    for (const [key, value] of Object.entries(groupedData)) {
      result.push({
        key: key,
        name: value.name,
        price: value.price,
        quantity: value.quantity,
      });
    }

    return result;
  }

  // 获取购物车商品并计算总价
  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await axios.get(`${config.baseUrl}/api/carts/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });

        const groupedData = groupByProductType(response.data);
        const checkoutItemNumber = response.data[0].checkout_item;
        localStorage.setItem("checkoutItemNumber", checkoutItemNumber);
        setCartItems(groupedData);

        // 计算总价
        // const total = response.data.reduce(
        //   (sum, item) => sum + item.price * item.quantity,
        //   0
        // );
      } catch (error) {
        message.error("Get cart items failed, please try again later");
        navigate("/orderform");
      }
    };
    fetchCartItems();
  }, [navigate]);

  // 显示总价和折扣后的价格
  const [totalPrice, setTotalPrice] = useState(0);
  const [discountedTotal, setDiscountedTotal] = useState(0);
  //编写一下apply coupon接口的应用
  const validateCoupon = () => {
    applyCoupon();
  };
  // 自动转换优惠券代码为大写字母
  const handleCouponChange = (e) => {
    const upperCaseCode = e.target.value.toUpperCase();
    setCouponCode(upperCaseCode);
  };
  // 应用优惠券
  const applyCoupon = async () => {
    try {
      const checkoutItemNumber = localStorage.getItem("checkoutItemNumber");
      const checkout_item_id = parseInt(checkoutItemNumber, 10);
      if (isNaN(checkout_item_id)) {
        message.error("checkout item number is not valid");
        return;
      }
      const response = await axios.post(
        `${config.baseUrl}/api/coupon/apply_coupon/`,
        { coupon_code: couponCode, checkout_item_id: checkout_item_id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (response.data.success) {
        message.success("coupon applied successfully");
        //trigger above http get checkout effect
        setRefetch(!refetch);
        setDiscount(response.data.discount);
        setCouponError(null);
      } else {
        setCouponError("invalid coupon");
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        if (error.response.data.errors.non_field_errors) {
          message.error(error.response.data.errors.non_field_errors[0]);
          setCouponError(error.response.data.errors.non_field_errors[0]);
        } else {
          message.error(error.response.data.errors.coupon_code[0]);
          setCouponError(error.response.data.errors.coupon_code[0]);
        }
      }
    }
  };

  return (
    <Card
      title={
        <span style={{ fontSize: "30px", fontWeight: "bold" }}>Checkout</span>
      }
      style={{ width: "93%", maxWidth: 1200, margin: "15px auto" }}
    >
      <Form layout="vertical">
        {/* 商品清单列表 */}
        <Table columns={columns} dataSource={cartItems} pagination={false} />
        <Divider />
        {/* 地址选择 */}
        <Form.Item label="Choose Delivery Address">
          <Select
            placeholder="Select your address"
            onChange={handleAddressChange}
          >
            {savedAddresses.map((address) => (
              <Option key={address.id} value={address.id}>
                <div style={{ fontSize: "10px" }}>{address.address}</div>
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Button type="dashed" onClick={handleAddAddress} style={{}}>
          {" "}
          Add Address{" "}
        </Button>
        <Divider />

        {/* 优惠券代码输入和验证 */}
        <Form.Item label="Coupon Code">
          <Input
            placeholder="Enter your coupon code"
            value={couponCode}
            onChange={handleCouponChange}
            style={{ width: "60%", marginRight: 8 }}
            disabled={!applyButtonEnable}
          />
          <Button
            type="primary"
            onClick={validateCoupon}
            style={{ marginTop: "10px" }}
            disabled={!applyButtonEnable}
          >
            Apply Coupon
          </Button>
          {couponError && <p style={{ color: "red" }}>{couponError}</p>}
        </Form.Item>

        <Divider />

        {/* 支付方式选择 */}
        <Form.Item label="Select Payment Method">
          <Radio.Group
            onChange={handlePaymentMethodChange}
            value={paymentMethod}
          >
            <Radio value="bitcoin_payment">Bitcoin</Radio>
            <Radio value="western_union">Western Union</Radio>
            <Radio value="paypal">Paypal</Radio>
          </Radio.Group>
        </Form.Item>

        <Divider />

        {/* 总计显示 */}
        <Form.Item>
          <h3>Total: ${totalPrice ? totalPrice.toFixed(2) : 0}</h3>
          <h3 style={{ color: "red" }}>
            Discount: ${discount ? discount.toFixed(2) : 0}
          </h3>
          <h3>Discounted Total: ${discountedTotal.toFixed(2)}</h3>
        </Form.Item>

        {/* 结账按钮 */}
        <Form.Item>
          <Button
            type="primary"
            onClick={handleCheckout}
            disabled={!selectedAddress}
            style={{ width: "100%" }}
          >
            Proceed to Pay
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Checkout;
