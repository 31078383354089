import { Card } from "antd";

export default function WesterUnion() {
  return (
    <Card title="Western Union">
      <p>
        If you want to Western Union or MoneyGram, please send the money to the
        pls send email to
        <a href="mailto:service@getyourfakeid.com">service@getyourfakeid.com</a>
      </p>
    </Card>
  );
}
