import React, { useState } from "react";
import config from "../config";
import {
  Card,
  Typography,
  Steps,
  Button,
  Divider,
  Alert,
  Space,
  Form,
  Input,
  message,
} from "antd";
import {
  DollarOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
  CopyOutlined,
} from "@ant-design/icons";

const { Title } = Typography;
const { Step } = Steps;

const Paypal = ({ orderData }) => {
  const [paymentInfo, setPaymentInfo] = useState({
    paypal_payer_full_name: orderData.paypal_payer_full_name || "",
    paypal_payer_email: orderData.paypal_payer_email || "",
    paypal_transaction_id: orderData.paypal_transaction_id || "",
    paypal_actual_amount_after_fee:
      orderData.paypal_actual_amount_after_fee || "",
    paypal_note: orderData.paypal_note || "",
    checkout_hash: orderData.checkout_hash,
  });

  // Add loading state variable
  const [isSubmitting, setIsSubmitting] = useState(false);

  // 检查是否已提交支付信息
  const isPaymentSubmitted = !!orderData.paypal_payer_full_name;

  const handleInputChange = (field, value) => {
    setPaymentInfo((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = async () => {
    // Prevent resubmission if already submitting
    if (isSubmitting) return;

    try {
      setIsSubmitting(true);
      const response = await fetch(`${config.baseUrl}/api/paypal/submit/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(paymentInfo),
      });

      if (response.ok) {
        message.success("Payment information submitted successfully");
        window.location.reload();
      } else {
        message.error("Submission failed, please try again");
      }
    } catch (error) {
      message.error("Error submitting, please try again later");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card
      title={
        <span style={{ fontSize: "24px", fontWeight: "bold" }}>
          PayPal Payment Guide
        </span>
      }
      style={{ width: "100%", maxWidth: 1200, margin: "15px auto" }}
    >
      {isPaymentSubmitted && (
        <Alert
          message="Payment Information Submitted needs 24 hours to verify"
          description="Your payment information has been submitted and is being processed."
          type="success"
          showIcon
          style={{ marginBottom: 20 }}
        />
      )}

      {!isPaymentSubmitted && (
        <Alert
          message="Important Notice"
          description="Please follow these steps to complete your PayPal payment. Your order will be processed after payment completion."
          type="info"
          showIcon
          icon={<InfoCircleOutlined />}
          style={{ marginBottom: 20 }}
        />
      )}

      <Steps direction="vertical" current={-1} style={{ marginBottom: 20 }}>
        <Step
          title="Open the payment paypal payment link"
          description={
            <span>
              Click{" "}
              <a
                href="https://www.paypal.com/ncp/payment/U2M8QJ5244H5J"
                target="_blank"
                rel="noreferrer noopener"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    "https://www.paypal.com/ncp/payment/U2M8QJ5244H5J",
                    "_blank"
                  );
                }}
              >
                here
              </a>{" "}
              to open PayPal payment page. If you don't have an account, you'll
              need to register first.
            </span>
          }
          icon={<DollarOutlined />}
        />
        <Step
          title="Send Payment"
          description={
            <span>
              Enter the amount{" "}
              <span
                style={{
                  color: "#ff4d4f", // antd 的红色
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                ${Number(orderData.total).toFixed(2)}
              </span>{" "}
              <Button
                type="link"
                icon={<CopyOutlined />}
                onClick={() => {
                  navigator.clipboard
                    .writeText(Number(orderData.total).toFixed(2))
                    .then(() => {
                      message.success("Amount copied to clipboard");
                    })
                    .catch(() => {
                      message.error("Failed to copy amount");
                    });
                }}
                style={{ padding: "4px" }}
              >
                Copy
              </Button>{" "}
              to the payment page
            </span>
          }
          icon={<DollarOutlined />}
        />
        <Step
          title="Confirm Payment"
          description="Complete the payment process and save the transaction and Fill in the following information"
          icon={<CheckCircleOutlined />}
        />
      </Steps>

      <Divider />

      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <div>
          <Title level={4}>PayPal Payment Information</Title>
          <Form layout="vertical">
            <Form.Item
              label="Payer's Full Name"
              required
              rules={[
                { required: true, message: "Please enter payer's full name" },
              ]}
            >
              <Input
                value={paymentInfo.paypal_payer_full_name}
                onChange={(e) =>
                  handleInputChange("paypal_payer_full_name", e.target.value)
                }
                disabled={isPaymentSubmitted}
              />
            </Form.Item>
            <Form.Item
              label="Payer's Email"
              required
              rules={[
                { required: true, message: "Please enter payer's email" },
                { type: "email", message: "Please enter a valid email" },
              ]}
            >
              <Input
                type="email"
                value={paymentInfo.paypal_payer_email}
                onChange={(e) =>
                  handleInputChange("paypal_payer_email", e.target.value)
                }
                disabled={isPaymentSubmitted}
              />
            </Form.Item>
            <Form.Item
              label="Transaction ID"
              required
              rules={[
                { required: true, message: "Please enter transaction ID" },
              ]}
            >
              <Input
                value={paymentInfo.paypal_transaction_id}
                onChange={(e) =>
                  handleInputChange("paypal_transaction_id", e.target.value)
                }
                disabled={isPaymentSubmitted}
              />
            </Form.Item>
            <Form.Item
              label="Actual Amount After Fee"
              required
              rules={[
                { required: true, message: "Please enter actual amount" },
                { type: "number", message: "Please enter a valid number" },
              ]}
            >
              <Input
                type="number"
                value={paymentInfo.paypal_actual_amount_after_fee}
                onChange={(e) =>
                  handleInputChange(
                    "paypal_actual_amount_after_fee",
                    e.target.value
                  )
                }
                disabled={isPaymentSubmitted}
                placeholder="Example: 70"
              />
            </Form.Item>
            <Form.Item label="Notes" rules={[{ required: false }]}>
              <Input.TextArea
                value={paymentInfo.paypal_note}
                onChange={(e) =>
                  handleInputChange("paypal_note", e.target.value)
                }
                disabled={isPaymentSubmitted}
              />
            </Form.Item>
          </Form>
        </div>
      </Space>
      <Divider />

      <div style={{ textAlign: "center", marginTop: 20 }}>
        {!isPaymentSubmitted && (
          <Button
            type="primary"
            onClick={handleSubmit}
            style={{ marginTop: 10 }}
            loading={isSubmitting}
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        )}
        <div>{orderData.paymentInfo}</div>
      </div>
    </Card>
  );
};

export default Paypal;
